import React from 'react'
import { Text } from 'theme-ui'
import TextList from '@components/TextList'
import { FaRegClock } from 'react-icons/fa'
import rv from '@components/utils/buildResponsiveVariant'
import Moment from 'react-moment';
import 'moment/locale/it';

const CardFooterInfo = ({ variant, date, timeToRead }) => (
  <TextList nowrap>
    <Moment locale="it" format="LL">{date}</Moment>
    {timeToRead && (
      <Text sx={{ variant: rv(variant, 'timeToRead') }}>
        <FaRegClock css={{ verticalAlign: `middle` }} /> {timeToRead} min
      </Text>
    )}
  </TextList>
)

export default CardFooterInfo
